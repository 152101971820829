<template>
  <div>
    <h1>Google Search Console Daten</h1>
    <div v-if="loading">Laden...</div>
    <div v-if="error">{{ error }}</div>
  </div>
</template>

<script>
/* const { google } = require('googleapis')

async function fetchData() {
  const auth = new google.auth.GoogleAuth({
    keyFile: '/pfad/zur/ihrem/service-account-file.json', // Ersetzen Sie dies durch den Pfad zu Ihrer Service Account-Datei
    scopes: ['https://www.googleapis.com/auth/webmasters.readonly'],
  })

  const client = await auth.getClient()

  const webmasters = google.webmasters({ version: 'v3', auth: client })

  const res = await webmasters.searchanalytics.query({
    siteUrl: 'https://rechtsanwaelte-schuette.de/', // Ersetzen Sie dies durch die URL Ihrer Website
    requestBody: {
      startDate: '2021-01-01',
      endDate: '2021-12-31',
      dimensions: ['query'],
      rowLimit: 10,
    },
  })

  console.log(res.data)
}

fetchData().catch(console.error) */
</script>