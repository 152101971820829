import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import StatsComponent from './components/StatsComponent.vue'
import GoogleComponent from './components/GoogleComponent.vue'
import HomeView from './HomeView.vue'
import VueApexCharts from "vue3-apexcharts";




const routes = [
  { path: '/', component: HomeView },
  { path: '/stats', component: StatsComponent },
  { path: '/google', component: GoogleComponent },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

console.log(router)

const app = createApp(App)
app.use(VueApexCharts);
app.use(router)
app.mount('#app')